import { useMemo } from 'react';
import get from 'lodash/get';
import { ControllerProps, FieldError, useFormContext, useFormState } from 'react-hook-form';

export function useFormError<T>(
  props: Pick<ControllerProps, 'rules' | 'name'> & { label?: any }
): string | undefined {
  const context = useFormContext<T>();
  const { errors } = useFormState<T>(context);
  const error: FieldError | undefined = errors ? get(errors, props.name) : undefined;

  return useMemo(() => {
    if (!error) {
      return undefined;
    }
    if (error.message) {
      return error.message;
    }

    const message = get(props.rules, error.type)?.message ?? '';

    if (message) {
      return message;
    }

    const label = props.label && typeof props.label === 'string' ? props.label : 'This field';

    switch (error.type) {
      case 'required':
        return `${label} is required`;
      case 'min':
        return `${label} must be greater than ${props.rules?.min}`;
      case 'max':
        return `${label} must be less than ${props.rules?.max}`;
      case 'minLength':
        return `${label} must be at least ${props.rules?.minLength} characters.`;
      case 'maxLength':
        return `${label} may not be over ${props.rules?.maxLength} characters.`;
    }
  }, [error, props.label, props.rules]);
}
