import { Alert, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useSolvForm } from '../../core/form/useSolvForm';
import { analyticsTrackEvent } from '../../core/analytics';
import {
  LOGIN_RESET_PASSWORD_FAILURE,
  LOGIN_RESET_PASSWORD_SUCCESS,
} from '../../core/analytics/events';
import { passwordResetClearErrors, passwordResetRequested } from '../../actions/login';
import { getResetClinicAccountPasswordUrl } from '../../core/dapi/clinicAccounts';
import { FormProvider } from 'react-hook-form';
import { FormElement } from '../../core/form/common';
import { useDispatch } from 'react-redux';
import { HttpError, httpPostJson } from '../../core/http/http';
import styled from 'styled-components';
import { PropType } from '../../core/util/generics';
import { AlertProps } from 'antd/lib/alert';
import { SolvInput } from '../../core/form/antd/SolvInput';

const AlertContainer = styled.div`
  padding-bottom: 16px;
`;

type Form = {
  email: string;
};

export function PasswordResetModal({ onClose }: { onClose: () => void }) {
  const form = useSolvForm<Form>();
  const dispatch = useDispatch();

  const [error, setError] =
    useState<{ message: string; title: string; type: PropType<AlertProps, 'type'> }>();
  const [success, setSuccess] = useState(false);

  const onSuccess = useCallback(() => {
    analyticsTrackEvent(LOGIN_RESET_PASSWORD_SUCCESS);
    dispatch(passwordResetRequested());
    setSuccess(true);
  }, [dispatch]);

  const onError = useCallback(() => {
    analyticsTrackEvent(LOGIN_RESET_PASSWORD_FAILURE);
  }, []);

  const onSubmit = useCallback(
    async (form: Form) => {
      try {
        await httpPostJson(getResetClinicAccountPasswordUrl(), { email: form.email });
        onSuccess();
      } catch (ex) {
        onError();
        if (ex instanceof HttpError) {
          if (ex.toString().includes('SSO enabled')) {
            setError({
              title: 'Your account is set-up for SSO',
              message:
                'You’ll have to reset your password using the 3rd party website you signed up with, such as Google or Microsoft.',
              type: 'info',
            });
          } else {
            setError({
              title: 'We were unable to send a password reset email.',
              message: ex.toString(),
              type: 'error',
            });
          }
        } else {
          setError({
            title: 'We were unable to send a password reset email.',
            message: ex instanceof Error ? (ex.message as string) : 'Something went wrong.',
            type: 'error',
          });
        }
      }
      dispatch(passwordResetClearErrors());
    },
    [dispatch, onError, onSuccess]
  );

  return (
    <Modal
      title="Reset Password"
      visible={true}
      onOk={form.handleSubmit(onSubmit)}
      onCancel={onClose}
    >
      {error && (
        <AlertContainer>
          <Alert type={error.type} message={error.title} description={error.message} />
        </AlertContainer>
      )}
      {success && (
        <AlertContainer>
          <Alert
            type={'info'}
            message="Check your email"
            description={'Follow the link to reset your password.'}
          />
        </AlertContainer>
      )}
      <FormProvider {...form}>
        <form>
          <FormElement>
            <SolvInput name={'email'} type={'email'} label={'Email Address'} required={true} />
          </FormElement>
        </form>
      </FormProvider>
    </Modal>
  );
}
