import { isMobile } from './device/index';
import { analyticsTrackEvent } from '../analytics';
import { MANAGE_LOADED_IN_IFRAME } from '../analytics/events';
import { getClinicAccountPermissions } from './clinicAccounts';

const getTrackingProperties = (account) => ({
  username: account.username,
  role: account.role,
  permissions: getClinicAccountPermissions(account),
  isMobile: isMobile(),
});

const trackIfLoadedInIframe = () => {
  if (window.location !== window.parent.location) {
    analyticsTrackEvent(MANAGE_LOADED_IN_IFRAME, {
      currentLocation: window.location.href,
      parentLocation: window.parent.location.host,
    });
  }
};

export { getTrackingProperties, trackIfLoadedInIframe };
