import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormProps } from 'react-hook-form/dist/types';
import { DefaultValues, KeepStateOptions } from 'react-hook-form/dist/types/form';

/**
 * Same as react-hook-form's useForm, but allows you to asynchronously load in default values
 */
export function useSolvForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(props: UseFormProps<TFieldValues, TContext> = {}, resetProps?: KeepStateOptions) {
  const loaded = useRef(false);

  const setLoaded = useCallback((value: boolean = true) => {
    loaded.current = value;
  }, []);

  const [defaultValues, setDefaultValues] = useState<DefaultValues<TFieldValues>>();

  const ref = useForm<TFieldValues>({
    ...(props as any),
  });

  const prevInitialValues = useRef<DefaultValues<TFieldValues>>();

  // Reloads the form once initial values load in.
  useEffect(() => {
    if (loaded.current) {
      return;
    }
    // Return if the initial values were already set, to prevent infinite rerender
    if (
      prevInitialValues.current === props.defaultValues ||
      JSON.stringify(prevInitialValues.current) === JSON.stringify(props.defaultValues)
    ) {
      return;
    }

    prevInitialValues.current = props.defaultValues;

    if (props.defaultValues == null) {
      return;
    }

    const newValues = { ...ref.getValues(), ...props.defaultValues };
    ref.reset(newValues, resetProps);
    setDefaultValues(props.defaultValues);
  }, [loaded, props.defaultValues, ref, resetProps]);

  return {
    ...ref,
    loaded,
    setLoaded,
    key: loaded.toString(),
    defaultValues,
  };
}
