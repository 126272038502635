import { Alert } from 'antd';
import { useEffect } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import { LOGO_DARK_PINK_DOT } from '../../components/Logo/Logo';
import { trackIfLoadedInIframe } from '../../core/util/tracking';
import { NewsFeed, NewsFeedResponse } from './NewsFeed';

interface LoginProps {
  isComingFromAccountOnboarding?: boolean;
  isComingFromResetPassword?: boolean;
  persistentStateLoaded?: boolean;
  redirectUrl?: string;
  viewport?: any;
  login?: any;
  newsFeed?: NewsFeedResponse;
}

export default function Login({ login, redirectUrl, newsFeed }: LoginProps) {
  useEffect(() => {
    trackIfLoadedInIframe();
  }, []);

  const isPasswordResetRequested = !!login?.passwordResetRequested;

  return (
    <div className="min-h-dvh font-sans grid grid-cols-1 lg:grid-cols-2 overflow-x-hidden">
      <div className="hidden lg:block h-dvh self-stretch order-1 lg:order-none fixed w-[45%]">
        <div className="bg-purple-50 h-full rounded-xl px-8 flex flex-col items-center justify-center relative tw-reset">
          <div className="h-dvh w-36 bg-purple-50 rotate-3 absolute top-0 right-0 translate-x-[50%] scale-y-110"></div>
          <div className="w-full flex flex-col items-center justify-center">
            <div className="py-8 h-dvh overflow-y-auto pr-4 hide-scrollbar">
              <NewsFeed items={newsFeed?.data ?? []} />
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div className="font-sans h-full min-h-dvh flex flex-col items-center justify-center px-6 text-gray-900 gap-12">
        {isPasswordResetRequested && (
          <Alert
            message="Check your email"
            description="Follow the link to reset your password."
            type="info"
            closable
            showIcon
          />
        )}
        <div className="max-w-[350px] w-full space-y-12 py-12">
          <img
            alt="Solv"
            src={`/images/logo/${LOGO_DARK_PINK_DOT}`}
            width={319}
            height={120}
            className="w-[60px] h-auto object-contain"
          />
          <div className="rounded-lg  space-y-8">
            <LoginForm redirectUrl={redirectUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}
