import { Alert, Button, Col, Divider, Input, Row } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { LoginErrorMessages } from '../../constants';
import { getAuth } from '../../core/auth';
import { useGlobalLoginEvent } from '../../core/auth/global';
import { getLoginUrl } from '../../core/dapi/login';
import { useSolvForm } from '../../core/form/useSolvForm';
import { HttpError, httpPostJson } from '../../core/http/http';
import { AfterLoginOpts, onLoginSuccess } from '../../ducks/login';
import { LoginToken } from '../../types/RootState';
import { SingleSignOn } from '../SingleSignOn/SingleSignOn';
import { PasswordResetModal } from './PasswordResetModal';
import { SolvInput } from '../../core/form/antd/SolvInput';

type Form = {
  username: string;
  password: string;
};

type FormattedError =
  | {
      message: string;
      description: string;
    }
  | undefined;

const useAutoLoginWhenTokenSet = (disabled: boolean, opts?: AfterLoginOpts) => {
  const dispatch = useDispatch();
  const processing = useRef<boolean>(false);

  // If they land on the login page but already have the token set, just redirect them
  useEffect(() => {
    if (disabled || processing.current) {
      return;
    }
    const auth = getAuth();
    if (auth) {
      onLoginSuccess(dispatch, auth, opts).catch();
    }
  }, [disabled, dispatch, opts]);

  useGlobalLoginEvent(() => {
    if (disabled || processing.current) {
      return;
    }
    // dapi auth key was set, probably from another tab logging in, lets log this one back in too
    const auth = getAuth();
    if (auth) {
      processing.current = true;
      onLoginSuccess(dispatch, auth, opts)
        .catch()
        .finally(() => {
          processing.current = false;
        });
    }
  });
};

const useSubmitLogin = (opts?: AfterLoginOpts) => {
  const dispatch = useDispatch();
  return useMutation<void, HttpError | Error, Form>(async (form) => {
    const { data: token } = await httpPostJson<{ data: LoginToken }>(getLoginUrl(), {
      client_id: DAPI_CLIENT_ID,
      client_secret: DAPI_CLIENT_SECRET,
      username: form.username,
      password: form.password,
      grant_type: 'clinic',
    });
    await onLoginSuccess(dispatch, token, opts);
  });
};

export default function LoginForm(opts: AfterLoginOpts) {
  const form = useSolvForm<Form>();
  const [showResetModal, setShowResetModal] = useState(false);

  const { mutate, isLoading, error } = useSubmitLogin({
    ...opts,
    clearRemixCache: true,
  });

  useAutoLoginWhenTokenSet(isLoading, opts);

  const handleSubmit = form.handleSubmit((form) => {
    mutate(form);
  });

  const formattedError: FormattedError = useMemo(() => {
    if (!error) {
      return undefined;
    }

    let result = {
      message: 'We were unable to log you in.',
      description: 'message' in error ? error.message : LoginErrorMessages.GENERIC,
    };

    if (error instanceof HttpError) {
      const formatted = error.formatted();
      if (formatted === 'Invalid username or password.') {
        result.description = LoginErrorMessages.BAD_CREDENTIALS;
      } else if (
        formatted ===
        'This clinic account is SSO enabled, please log in using one of the SSO buttons.'
      ) {
        result.message = 'This clinic account is SSO (single sign-on) enabled.';
        result.description = LoginErrorMessages.SSO_ENABLED;
      }
    }

    return result;
  }, [error]);

  return (
    <div className="space-y-8 ">
      {formattedError && (
        <div className="bg-red-50 rounded-lg p-4 text-red-900 space-y-2 text-sm">
          <div className="font-semibold">{formattedError.message}</div>
          <div className="">{formattedError.description}</div>
        </div>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="flex flex-col gap-2">
            <label htmlFor="username" className="font-medium text-sm">
              Email
            </label>
            <SolvInput
              id="username"
              name="username"
              className="border-gray-300 rounded-lg h-10 shadow-sm"
              data-testid="loginUsername"
              type="text"
              autoFocus
              autoComplete="email"
              required
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-baseline">
              <label htmlFor="password" className="font-medium text-sm">
                Password
              </label>
              <button
                className="cursor-pointer text-xs underline text-gray-600 hover:text-gray-500 hover:underline appearance-none bg-transparent"
                type="button"
                onClick={() => setShowResetModal(true)}
              >
                forgot?
              </button>
            </div>
            <SolvInput
              name="password"
              id="password"
              className="border-gray-300 rounded-lg h-10 shadow-sm"
              data-testid="loginPassword"
              type="password"
              autoComplete="current-password"
              required
            />
          </div>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            className="w-full h-10 bg-blue-600 border-blue-600 font-semibold rounded-lg shadow hover:bg-blue-500 hover:border-blue-500 duration-75 hover:shadow-md transition-all"
          >
            Log in
          </Button>
        </form>

        <Divider>
          <span className="text-xs tracking-wide font-semibold text-gray-400">OR LOGIN WITH</span>
        </Divider>
        <SingleSignOn hideDivider />
        {showResetModal && <PasswordResetModal onClose={() => setShowResetModal(false)} />}
      </FormProvider>
    </div>
  );
}
